<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios'
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import MyCurrency from "./my-currency-input.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
//import vSelect from 'vue-select'
//import DatePicker from "vue2-datepicker";
// import Swal from "sweetalert2";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */
export default {
    page: {
        title: "Budget Plan Proposal",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, vSelect, MyCurrency, DatePicker },
    data() {
        return {
            //   tableData: tableData,
            title: "Budget Plan Proposal",
            items: [
                {
                    text: "Project",
                    href: "/"
                },
                {
                    text: "Budget Plan",
                    active: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "task", sortable: true },
                //{ key: "deskripsi", sortable: true },
                { key: "kuantitas", label: 'Quantity', sortable: true },
                { key: "satuan", label: 'Unit', sortable: true },
                { key: "harga_satuan", label: 'Unit Price', sortable: true },
                { key: "harga_total", label: "Allocation(Rp)", sortable: true },
                { key: "nilai_realisasi", label: 'Realization(Rp)', sortable: true },
                'action'
            ],

            totalRowsRealisasi: 1,
            currentPageRealisasi: 1,
            perPageRealisasi: 10,
            pageOptionsRealisasi: [10, 25, 50, 100],
            filterRealisasi: null,
            filterOnRealisasi: [],
            sortByRealisasi: "age",
            sortDescRealisasi: false,
            id_realisasi: null,
            fieldsRealisasi: [
                // { key: "kode_task",label:'Code Task', sortable: true },
                { key: "task", sortable: true },
                { key: "keterangan", label: 'Notes', sortable: true },
                { key: "harga_total", label: 'Total Price', sortable: true },
                'action'
            ],
            dataRealisasi: [],
            detailAnggaran: {},
            formRealisasi: {
                id: null,
                harga_total: null,
                keterangan: null,
                start: null,
                end: null,
                file: null
            },

            baseapi: localStorage.getItem("baseapi"),
            idProyek: localStorage.getItem("idProyek"),
            user: JSON.parse(localStorage.getItem('user')),
            dataAbility: [],
            report: [],
            selected: null,
            options: [
                { value: null, text: 'Please select an option' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Selected Option' },
                { value: { C: '3PO' }, text: 'This is an option with object value' },
                { value: 'd', text: 'This one is disabled', disabled: true }
            ],
            optionParent: [{ text: 'Parent', value: true }, { text: 'Task', value: false }],
            daterange: "",
            dataUser: [],
            dataForm: {
                task: null,
                quality: null,
                description: null,
                unit: null,
                unit_price: null,
                is_parent: null,
                id_parent: null,
                totalPrice: null
            },

            is_parent: false,
            id_parent: false,
            dataAnggaran: [],
            formEdit: {}
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.dataAbility.length;
        }
    },
    mounted() {
        this.loaddata()
        this.getUser()
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */

        formattedDate(tanggal) {
            return moment(tanggal).format('DD, MMMM yyyy');
        },

        formatUang: value => (value + '').replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        totalBudget() {
            if (this.dataAnggaran == 0) {
                return 0;
            } else {
                return this.dataAnggaran.reduce((acc, ele) => {
                    console.log(ele.is_parent)
                    if (ele.is_parent == true) {
                        return acc + parseInt(ele.harga_total);
                    } else {
                        return acc + parseInt(0);
                    }

                }, 0);
            }
        },
        deleteData(id) {
            Swal.fire({
          title: 'Are you sure to delete this budget?',
          // text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya'
        }).then((result) => {
          if (result.isConfirmed) {
            axios
                .delete(
                    this.baseapi + `api/anggaran/` + id,
                    {
                        headers: {
                            Authorization: `Bearer ` + this.user.accessToken
                        }
                    }
                )
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil Dihapus',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.loaddata()
                    return res;
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                });
          }})
            
        },

        onSubmit(e) {
            e.preventDefault();
            this.$validator.validateAll();

            if (this.dataForm.id_parent != null) {
                this.is_parent = false
                this.id_parent = this.dataForm.id_parent
            } else {
                this.is_parent = true
                this.id_parent = 0
            }



            console.log(this.id_parent)
            axios.post(this.baseapi + 'api/anggaran', {
                id_user: this.user.id,
                id_proyek: this.idProyek,
                task: this.dataForm.task,
                deskripsi: this.dataForm.description,
                kuantitas: this.dataForm.quality,
                satuan: this.dataForm.unit,
                harga_satuan: this.dataForm.unit_price,
                id_parent: this.id_parent,
                is_parent: this.is_parent,
            }, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ` + this.user.accessToken
                }
            })
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil Ditambah',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.loaddata()

                    this.dataForm = {}
                    this.id_parent = null
                    this.is_parent = null
                    // Wait until the models are updated in the UI
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-tambah')
                    });
                    return res;
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                });
        },

        editData(id) {
            this.$validator.validateAll();
            if (this.formEdit.id_parent != null) {
                this.is_parent = false
                this.id_parent = this.formEdit.id_parent
            } else {
                this.is_parent = true
                this.id_parent = 0
            }



            console.log(this.id_parent)
            axios.put(this.baseapi + 'api/anggaran/' + id, {
                task: this.formEdit.task,
                deskripsi: this.formEdit.deskripsi,
                kuantitas: this.formEdit.kuantitas,
                satuan: this.formEdit.satuan,
                harga_satuan: this.formEdit.harga_satuan,
                id_parent: this.id_parent,
                is_parent: this.is_parent,
            }, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ` + this.user.accessToken
                }
            })
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil Ditambah',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.loaddata()

                    // Wait until the models are updated in the UI
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-edit')
                    });
                    return res;
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                });
        },

        // loaddata anggaran
        loaddata() {
            axios.get(this.baseapi + "api/anggaran?filter=id_proyek,=," + this.idProyek + '&sort=kode_task,ASC', {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataAnggaran = response.data.data;
            }).catch(error => {
                console.log(error)
                return error
            });
        },

        // detail
        getDetail(data) {
            this.formEdit = data;
            console.log(this.formEdit)
            this.$nextTick(() => {
                this.$bvModal.show('modal-edit')
            });
            // axios.get(this.baseapi + "api/ability/" + id, {
            //     headers: {
            //         Authorization: `Bearer ` + this.user.accessToken
            //     }
            // }).then(response => {
            //     this.report = response.data.data;
            //     this.$nextTick(() => {
            //         this.$bvModal.show('modal-edit')
            //     });
            // }).catch(error => {
            //     console.log(error)
            //     return error
            // });
        },

        getDetailRealisasi(data) {
            this.detailAnggaran = data;
            this.id_realisasi = data.id
            console.log(this.detailAnggaran)

            axios.get(this.baseapi + "api/realisasi_anggaran?filter=id_anggaran,=," + data.id, {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataRealisasi = response.data.data;
                this.$nextTick(() => {
                    this.$bvModal.show('modal-realisasi')
                });
            }).catch(error => {
                console.log(error)
                return error
            });
        },

        getDataRealisasi(data) {
            this.formRealisasi = data
            this.$nextTick(() => {
                this.$bvModal.show('modal-edit-realisasi')
            });
        },
        tambahRealisai() {
            var bodyFormData = new FormData();
            bodyFormData.append('id_user', this.user.id);
            bodyFormData.append('id_proyek', this.idProyek);
            bodyFormData.append('id_anggaran', this.detailAnggaran.id);
            bodyFormData.append('harga_total', this.formRealisasi.harga_total);
            bodyFormData.append('keterangan', this.formRealisasi.keterangan);
            bodyFormData.append('start', this.formRealisasi.start);
            bodyFormData.append('end', this.formRealisasi.end);
            bodyFormData.append('file', this.formRealisasi.file);

            axios.post(this.baseapi + 'api/realisasi_anggaran', bodyFormData, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: `Bearer ` + this.user.accessToken
                }
            })
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil Ditambah',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.loaddata()
                    axios.get(this.baseapi + "api/realisasi_anggaran?filter=id_anggaran,=," + this.id_realisasi, {
                        headers: {
                            Authorization: `Bearer ` + this.user.accessToken
                        }
                    }).then(response => {
                        this.dataRealisasi = response.data.data;
                        
                    }).catch(error => {
                        console.log(error)
                        return error
                    });
                    // Wait until the models are updated in the UI
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-tambah-realisasi')
                    });
                    return res;
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                });

        },

        editRealisasi(id) {
            var bodyFormData = new FormData();
            bodyFormData.append('id_user', this.user.id);
            bodyFormData.append('id_proyek', this.idProyek);
            bodyFormData.append('id_anggaran', this.detailAnggaran.id);
            bodyFormData.append('harga_total', this.formRealisasi.harga_total);
            bodyFormData.append('keterangan', this.formRealisasi.keterangan);
            bodyFormData.append('start', this.formRealisasi.start);
            bodyFormData.append('end', this.formRealisasi.end);
            bodyFormData.append('file', this.formRealisasi.file);

            axios.post(this.baseapi + 'api/realisasi_anggaran/' + id, bodyFormData, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: `Bearer ` + this.user.accessToken
                }
            })
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil Ditambah',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.loaddata()

                    // Wait until the models are updated in the UI
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-edit-realisasi')
                    });
                    return res;
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                });
        },
        // dataUser
        getUser() {
            axios.get(this.baseapi + "api/user", {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataUser = response.data.data;
            }).catch(error => {
                console.log(error)
                return error
            });
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.jenis === 'task') return 'table-success'
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div class="col-lg-6">
                <div class="text-lg-right"></div>
            </div>

        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <!-- <h4 class="card-title">Data Table</h4> -->
                        <div class="row mt-4">
                            <div class="col-sm-6">
                                <b-button variant="success" v-b-modal.modal-tambah><i class="fas fa-plus"></i>
                                    Add Budget Plan</b-button>
                            </div>
                            <div class="col-sm-6 text-end">
                            </div>
                            <!-- <div class="form-group row">
                                              
                                                                            </div> -->
                            <div class="col-sm-6 col-md-6">

                            </div>
                            <!-- Search -->
                            <div class="col-sm-6 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table :items="dataAnggaran" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                                head-variant="light">

                                <template #cell(task)="data">
                                    <h6 v-if="data.item.id_parent == 0">{{ data.item.kode_task + ' ' + data.item.task }}</h6>
                                    <span v-else :style="{ 'margin-left': 1 + '0px' }">
                                        {{ data.item.kode_task + ' ' + data.item.task }} </span>
                                </template>
                                <template v-slot:cell(progress)="row">
                                    <b-progress :value="row.value" :max="100" show-value height="2rem"
                                        variant="success"></b-progress>
                                </template>

                                <template v-slot:cell(kuantitas)="data">
                                    <span v-if="data.item.is_parent == true"></span>
                                    <span v-else>{{ data.item.kuantitas }}</span>
                                </template>

                                <template v-slot:cell(harga_satuan)="data">
                                    <span v-if="data.item.is_parent == true"></span>
                                    <span v-else>{{ formatUang(data.item.harga_satuan) }}</span>
                                </template>

                                <template v-slot:cell(harga_total)="data">
                                    <span>{{ formatUang(data.item.harga_total) }}</span>
                                </template>

                                <template #cell(action)="data">
                                    <div v-if="data.item.id_parent == null"></div>
                                    <div v-else>
                                        <a v-if="data.item.is_parent == false" href="javascript:void(0);"
                                            class="mr-3 text-success" v-b-tooltip.hover title="Realization"
                                            @click="getDetailRealisasi(data.item)">
                                            <i class="fas fa-hand-holding-usd font-size-24"></i>
                                        </a>
                                        <a class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip" title="Edit "
                                            @click="getDetail(data.item)">
                                            <i class="mdi mdi-pencil font-size-24"></i>
                                        </a>
                                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                                            @click="deleteData(data.item.id)">
                                            <i class="mdi mdi-trash-can font-size-24"></i>
                                        </a>

                                    </div>
                                </template>
                            </b-table>
                            <b-alert variant="success" show>
                                <h5>
                                    Total Budget: <span><strong>Rp. {{ formatUang(totalBudget()) }}</strong></span>
                                </h5>
                            </b-alert>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm"
                                            :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- modal realisasi -->
        <b-modal body-class="bg-white" id="modal-realisasi" scrollable title="Budget Realization" cancel-title="Close"
            ok-title="Accept" cancel-variant="outline-secondary" size="lg">
            <form ref="form" @submit.stop.prevent="tambahdata">
                <b-row>
                    <b-col md="12">
                        <div class="card">
                            <div class="card-body border-bottom">
                                <div id="todo-task" class="task-list">
                                    <div class="card task-box">
                                        <div class="progress progress-sm animated-progess" style="height: 3px">
                                            <div class="progress-bar" role="progressbar" style="width: 72%"
                                                aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="card-body">
                                            <!-- <div class="float-right ml-2">
                                                <div>{{ formattedDate(this.detailAnggaran.start) + ' sd. ' +
                                                    formattedDate(this.detailAnggaran.end) }}</div>
                                            </div> -->
                                            <div class="mb-3">
                                                <a href="#" class>{{ this.detailAnggaran.kode_task }}</a>
                                            </div>
                                            <div>
                                                <h5 class="font-size-16">
                                                    <a href="javascript: void(0);" class="text-dark">{{
                                                        this.detailAnggaran.task
                                                    }}</a>
                                                </h5>
                                                <p class="mb-1"></p>
                                            </div>
                                            <div>
                                                <h5 class="font-size-14">
                                                    {{ this.detailAnggaran.deskripsi }}
                                                </h5>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- end task card -->

                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 col-md-6">
                                        <b-button variant="success" size="sm" class="mb-2"
                                            v-b-modal.modal-tambah-realisasi><i class="fas fa-plus"></i>
                                            Add Realization</b-button>
                                    </div>

                                    <div class="col-sm-6 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Search:
                                                <b-form-input v-model="filterRealisasi" type="search"
                                                    placeholder="Search..."
                                                    class="form-control form-control-sm ml-2"></b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                <!-- Table -->
                                <div class="table-responsive mb-0">
                                    <b-table :items="dataRealisasi" :fields="fieldsRealisasi" responsive="sm"
                                        :per-page="perPageRealisasi" :current-page="currentPageRealisasi"
                                        :sort-by.sync="sortByRealisasi" :sort-desc.sync="sortDescRealisasi"
                                        :filter="filterRealisasi" :filter-included-fields="filterOnRealisasi"
                                        @filtered="onFiltered">
                                        <template #cell(action)="data">
                                            <b-button variant="info" style="margin-right:5px"
                                                @click="getDataRealisasi(data.item)"><i class="fas fa-edit"></i></b-button>
                                            <b-button variant="danger" @click="deleteData(data.item.id)"><i
                                                    class="far fa-trash-alt"></i></b-button>
                                        </template>
                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_length" class="dataTables_length">
                                            <label class="d-inline-flex align-items-center">
                                                Show&nbsp;
                                                <b-form-select v-model="perPage" size="sm"
                                                    :options="pageOptions"></b-form-select>&nbsp;entries
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination v-model="currentPage" :total-rows="rows"
                                                    :per-page="perPage"></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </form>


            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="danger" style="float:right" @click="$bvModal.hide('modal-detail')">
                        <i class="fa fa-floppy-o" />

                        Close
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- modal tambah realisasi -->
        <b-modal body-class="bg-white" id="modal-tambah-realisasi" scrollable title="Add Realization" cancel-title="Close"
            ok-title="Accept" cancel-variant="outline-secondary" size="lg">
            <form ref="form" @submit.stop.prevent="onSubmit">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Value" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="formRealisasi.harga_total" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Description" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="formRealisasi.keterangan" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Star Date" label-for="email" class="mb-3">
                            <date-picker v-model="formRealisasi.start" date append-to-body valueType="format"
                                lang="en"></date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="End Date" label-for="email" class="mb-3">
                            <date-picker v-model="formRealisasi.end" date append-to-body lang="en"
                                valueType="format"></date-picker>
                        </b-form-group>
                    </b-col>


                    <b-col md="12">
                        <b-form-group label="Payment Receipts" label-for="email" class="mb-3">
                            <b-form-file v-model="formRealisasi.file" placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."></b-form-file>
                            {{ formRealisasi.file }}
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>


            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="primary" style="float:right" @click="tambahRealisai">
                        <i class="fas fa-save" />

                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- modal edit realisasi -->
        <b-modal body-class="bg-white" id="modal-edit-realisasi" scrollable title="Edit Realization" cancel-title="Close"
            ok-title="Accept" cancel-variant="outline-secondary" size="lg">
            <form ref="form" @submit.stop.prevent="onSubmit">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Value" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="formRealisasi.harga_total" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Description" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="formRealisasi.keterangan" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Start Date" label-for="email" class="mb-3">
                            <date-picker v-model="formRealisasi.start" date append-to-body valueType="format"
                                lang="en"></date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="End Date" label-for="email" class="mb-3">
                            <date-picker v-model="formRealisasi.end" date append-to-body lang="en"
                                valueType="format"></date-picker>
                        </b-form-group>
                    </b-col>


                    <b-col md="12">
                        <b-form-group label="Payment Receipts" label-for="email" class="mb-3">
                            <b-form-file v-model="formRealisasi.file" placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."></b-form-file>
                            <a :href="formRealisasi.filename"><i class="mdi mdi-download font-size-18"></i> Download
                                File</a>
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>


            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="primary" style="float:right" @click="editRealisasi(formRealisasi.id)">
                        <i class="fas fa-save" />

                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- modal tambah -->
        <b-modal body-class="bg-white" id="modal-tambah" title="Budget Plan" cancel-title="Close" ok-title="Accept"
            cancel-variant="outline-secondary" size="lg">
            <form ref="form" @submit.stop.prevent="onSubmit">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Task" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="dataForm.task" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Description" label-for="email" class="mb-3">
                            <b-form-textarea id="textarea-default" v-model="dataForm.description"></b-form-textarea>
                            <span class="text-danger" v-if="errors.has('durasi')">
                                {{ errors.first('durasi') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Type" label-for="name" class="mb-3">
                            <v-select :reduce="user => user.value" label="text" v-model="dataForm.is_parent"
                                :options="optionParent" />

                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Parent" label-for="name" class="mb-3">
                            <v-select :reduce="user => user.id" label="task" v-model="dataForm.id_parent"
                                :options="dataAnggaran" :disabled="dataForm.is_parent" />

                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Quality" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task" type="number"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="dataForm.quality" :disabled="dataForm.is_parent" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Unit" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="dataForm.unit" :disabled="dataForm.is_parent" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Unit Price" label-for="name" class="mb-3">
                            <MyCurrency v-model="dataForm.unit_price" :disabled="dataForm.is_parent"></MyCurrency>
                            <!-- <b-form-input v-validate="'required'" name="Task" type="number"
                                                    :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                                    v-model="dataForm.unit_price" :disabled="dataForm.is_parent" /> -->

                        </b-form-group>
                    </b-col>
                </b-row>
            </form>


            <template #modal-footer>
                <div class="w-100">
                    <div>
                        <h5>Total Price</h5>
                    </div>

                    <div>
                        <h5><strong>Rp. {{ formatUang(dataForm.quality * dataForm.unit_price) }}</strong></h5>
                    </div>
                    <b-button variant="primary" style="float:right" @click="onSubmit">
                        <i class="fas fa-save" />

                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- modal edit -->
        <b-modal body-class="bg-white" id="modal-edit" title="Budget Plan" cancel-title="Close" ok-title="Accept"
            cancel-variant="outline-secondary" size="lg">
            <form ref="form" @submit.stop.prevent="onSubmit">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Task" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="formEdit.task" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Description" label-for="email" class="mb-3">
                            <b-form-textarea id="textarea-default" v-model="formEdit.deskripsi"></b-form-textarea>
                            <span class="text-danger" v-if="errors.has('durasi')">
                                {{ errors.first('durasi') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Type" label-for="name" class="mb-3">
                            <v-select :reduce="user => user.value" label="text" v-model="formEdit.is_parent"
                                :options="optionParent" />

                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Parent" label-for="name" class="mb-3">
                            <v-select :reduce="user => user.id" label="task" v-model="formEdit.id_parent"
                                :options="dataAnggaran" :disabled="formEdit.is_parent" />

                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Quality" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task" type="number"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="formEdit.kuantitas" :disabled="formEdit.is_parent" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Unit" label-for="name" class="mb-3">
                            <b-form-input v-validate="'required'" name="Task"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                v-model="formEdit.satuan" :disabled="formEdit.is_parent" />
                            <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                {{ errors.first('Nama Kegiatan') }}
                            </span>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Unit Price" label-for="name" class="mb-3">
                            <MyCurrency v-model="formEdit.harga_satuan" :disabled="formEdit.is_parent"></MyCurrency>
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>


            <template #modal-footer>
                <div class="w-100">
                    <div>
                        <h5>Total Price</h5>
                    </div>

                    <div>
                        <h5><strong>Rp. {{ formatUang(formEdit.kuantitas * formEdit.harga_satuan) }}</strong></h5>
                    </div>
                    <b-button variant="primary" style="float:right" @click="editData(formEdit.id)">
                        <i class="fas fa-save" />

                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>

</Layout></template>