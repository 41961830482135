<template>
<div class="idr">
    <b-form-input @keypress="onlyNumber" type="text" :disabled="valuedisable == true ? true:false" v-model="currentValue" @input="handleInput" />
</div>
</template>

<script>
export default {
  props: {
      value: {
        type: [String, Number],
        default: "",
      },
      valuedisable:{
        type: Boolean,
        default: false
      },
    },
    data: () => ({
      currentValue: '',
      disabled: 0,
    }),
    watch: {
      value: {
        handler(after) {
          this.currentValue = this.format(after)
        },
        immediate: true
      }
    },
    methods: {
      format: value => (value + '').replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      
      handleInput() {
        this.currentValue = this.format(this.currentValue)
        this.$emit('input', (this.currentValue + '').replace(/[^0-9]/g, ""))
      },
      onlyNumber ($event) {
        //console.log($event.keyCode); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
            $event.preventDefault();
        }
      } 
    }
};
</script>
